<template>
  <div class="container">
    <ManagerForm
        :first-name-imm="manager.firstName"
        :last-name-imm="manager.lastName"
        :phone-imm="manager.phone"
        :email-imm="manager.email"
        :login-imm="manager.login"
        :password-imm="manager.password"
        :is-edit="true"
        :available-contracts="manager.availableContracts"
        :manager-contacts="manager.myContracts"
        :is-show-info-imm="manager.isShowInfo"
        :is-login-used="isLoginUsed"
        @update="updateManager"
        @delete="showDeleteManagerModal"
        @createContract="onCreateContract"
        @deleteContract="onDeleteContract"
        @clearValidate="isLoginUsed = false"
    />
    <WarningDeleteManagerModal
        @delete="deleteManager"/>
  </div>
</template>

<script>
import ManagerForm from "@/components/Manager/ManagerForm";
import WarningDeleteManagerModal from "@/components/Manager/WarningDeleteManagerModal";
export default {
  name: "ManagersEdit",
  components: {WarningDeleteManagerModal, ManagerForm},
  data(){
    return {
      manager: {},
      isLoginUsed : false
    }
  },
  methods:{
    async getManagerById(id){
      const res = await this.$axios.get(`managers/${id}`);

      return res.data
    },
    async updateManager(data){
      this.isLoginUsed = false
      const res = await this.$axios.put(`managers/${this.manager.id}`,{
        login: data.login,
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        isShowInfo: data.isShowInfo
      })

      if (res.status == 400){
        this.isLoginUsed = true
      } else {
        if (res.data.id){
          this.$router.push({name: 'ManagersList'})
        }
      }


    },
    async deleteManager(){
      await this.$axios.delete(`managers/${this.manager.id}`)

      this.$router.go(-1)
    },
    async onCreateContract(contract){
      await this.$axios.post(`contract`,{
        "educationObjectId": contract.id,
        "managerId": this.manager.id
      })

      this.manager = {...await  this.getManagerById(this.$route.params.id)}

    },
    async onDeleteContract(contract){
      await this.$axios.delete(`contract/${contract.id}`)

      this.manager = {...await  this.getManagerById(this.$route.params.id)}

    },

    showDeleteManagerModal(){
      this.$modal.show("WarningDeleteManagerModal")
    }
  },
  async mounted() {
    this.manager = {...await  this.getManagerById(this.$route.params.id)}
  }
}
</script>

<style scoped lang="scss">
  .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 40px;
    width: 100%;
  }
</style>