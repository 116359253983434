<template>
  <modal name="WarningDeleteManagerModal" width="314px" height="165px">
    <div class="modal">
      <svg @click="$modal.hide('WarningDeleteManagerModal')" width="10" height="10" xmlns="http://www.w3.org/2000/svg"><g fill="#141213" fill-rule="evenodd" opacity=".4"><path d="M.397 9.722a.396.396 0 0 1-.28-.678L9.045.117a.396.396 0 0 1 .561 0 .396.396 0 0 1 0 .56L.678 9.607a.396.396 0 0 1-.28.116Z"/><path d="M9.326 9.722a.396.396 0 0 1-.282-.116L.117.678a.396.396 0 0 1 0-.561.396.396 0 0 1 .56 0l8.929 8.927a.396.396 0 0 1-.28.678Z"/></g></svg>
      <p class="modal__text">
        Вы уверены, что хотите удалить менеджера?
      </p>
      <div class="buttons">
        <button
          class="buttons__button buttons__button_no"
          @click="$modal.hide('WarningDeleteManagerModal')"
        >ОТМЕНА</button>
        <button
          class="buttons__button buttons__button_yes"
          @click="deleteManager">ДА</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'WarningDeleteManagerModal',
  methods:{
    deleteManager(){
      this.$emit('delete')
      this.$modal.hide('WarningDeleteManagerModal');
    }

  }
}
</script>

<style scoped lang="scss">
.modal{
  background: #FFFFFF;
  border: 0 solid #F2F2F2;
  box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
  border-radius: 4px;
  width: 314px;
  height: 165px;

  svg{
    cursor: pointer;
    margin-top: 16px;
    margin-left: 282px;

    &:hover{
      opacity: .5;
    }
  }

  &__text{
    font-family: Roboto, "sans-serif";
    font-weight: 300;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 17px;
    width: 262px;
    height: 42px;
    margin-left: 23px;
    margin-right: 23px;
    margin-top: 23px;
  }

  strong{
    font-weight: 700;
  }

  .buttons{
    margin-top: 26px;
    margin-left: 125px;


    &__button{
      background: #F1F1F1;
      border-radius: 4px;
      outline: 0;
      border: 0;
      cursor: pointer;
      height: 32px;
      font-family: Roboto, "sans-serif";
      font-size: 12px;
      letter-spacing: 2px;
      text-align: center;


      &:hover{
        filter: brightness(87.5%);
      }

      &_yes{
        margin-left: 13px;
        color: #CA4EBB;
        width: 62px;
      }

      &_no{
        color: #434343;
        width: 99px;

      }
    }
  }
}
</style>
